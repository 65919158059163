import React, { useContext, useState } from 'react'

import { request } from '../../../helpers/request'
import { Discount } from '../../../types/discount'
import { SMIconButton, SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMTextField } from '../../../SMComponents/SMInput'
import { Checkbox } from '@mui/material'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { AppContext } from '../../../contexts/AppContext'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'

export const StarOptions = ({ discount }: { discount: Discount }) => {
  const { handleOpenSnackBar } = useContext(AppContext)

  const [isLoadingAiFields, setIsLoadingAiFields] = useState(false)
  const [isLoadingGMapsFields, setIsLoadingGMapsFields] = useState(false)
  const [isLoadingHide, setIsLoadingHide] = useState(false)
  const [isEditingReservation, setIsEditingReservation] = useState(false)
  const [isEditingLocal, setIsEditingLocal] = useState(false)
  const [newLocal, setNewLocal] = useState(discount.local)
  const [isEditingDirecciones, setIsEditingDirecciones] = useState(false)
  const [newDirecciones, setNewDirecciones] = useState(discount.direcciones)
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [newTitle, setNewTitle] = useState(discount.titulo)
  const [isReservableInput, setIsReservableInput] = useState(discount.isReservable)
  const [reservationLinkInput, setReservationLinkInput] = useState(discount.reservationLink)

  const handleSaveDiscountChange = async (newFields: any) => {
    const response = await request('update_discount', {
      method: 'POST',
      body: JSON.stringify({
        discountId: discount.id,
        newFields,
      }),
    })
    console.log(response?.data || 'no data')
    handleOpenSnackBar({
      message: response?.message || 'Error',
    })
  }

  return (
    <div>
      <SMHorizontalDiv>
        {isEditingTitle ? (
          <SMTextField
            value={newTitle || ''}
            placeholder="Title"
            sx={{ backgroundColor: 'white', width: 300 }}
            onChange={(e: any) => setNewTitle(e.target.value)}
            onClick={(e: any) => e.stopPropagation()}
          />
        ) : (
          <p>Título: {newTitle}</p>
        )}
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingTitle
            setIsEditingTitle(!isEditingTitle)
            if (wantsToSaveChanges) {
              handleSaveDiscountChange({ titulo: newTitle })
            }
          }}
        >
          {isEditingTitle ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>
      <SMHorizontalDiv>
        {isEditingLocal ? (
          <SMTextField
            value={newLocal || ''}
            placeholder="Local"
            sx={{ backgroundColor: 'white', width: 300 }}
            onChange={(e: any) => setNewLocal(e.target.value)}
            onClick={(e: any) => e.stopPropagation()}
          />
        ) : (
          <p>Local: {newLocal}</p>
        )}
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingLocal
            setIsEditingLocal(!isEditingLocal)
            if (wantsToSaveChanges) {
              handleSaveDiscountChange({ local: newLocal })
            }
          }}
        >
          {isEditingLocal ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>
      <SMHorizontalDiv>
        {isEditingDirecciones ? (
          <SMTextField
            value={newDirecciones || ''}
            placeholder="Direccion"
            sx={{ backgroundColor: 'white', width: 300 }}
            onChange={(e: any) => setNewDirecciones(e.target.value)}
            onClick={(e: any) => e.stopPropagation()}
          />
        ) : (
          <p>Direcciones: {newDirecciones}</p>
        )}
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingDirecciones
            setIsEditingDirecciones(!isEditingDirecciones)
            if (wantsToSaveChanges) {
              handleSaveDiscountChange({ direcciones: newDirecciones })
            }
          }}
        >
          {isEditingDirecciones ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>
      <p>GMapsQueries: {discount.gMapsQueries?.join(' ; ')}</p>
      <SMHorizontalDiv>
        <p>MainTagV3: {discount.mainTagV3?.join(', ')}</p>
      </SMHorizontalDiv>

      <p>Regiones: {discount.regiones?.length == 16 ? 'TODAS' : discount.regiones?.join('. ')}</p>
      <SMHorizontalDiv>
        <SMVerticalDiv>
          {isEditingReservation ? (
            <>
              <SMHorizontalDiv>
                <p>Es reservable</p>
                <Checkbox
                  disabled={!discount.mainTagV3?.includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL)}
                  checked={!!isReservableInput}
                  onClick={(e: any) => {
                    setIsReservableInput(!isReservableInput)
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                />
              </SMHorizontalDiv>
              <SMTextField
                value={reservationLinkInput || ''}
                placeholder="Link de reserva"
                sx={{ backgroundColor: 'white', width: 300 }}
                onChange={(e: any) => setReservationLinkInput(e.target.value)}
                onClick={(e: any) => e.stopPropagation()}
              />
            </>
          ) : (
            <>
              <p>Es reservable: {isReservableInput ? 'Si' : 'No'}</p>
              {isReservableInput ? <p>Link: {reservationLinkInput}</p> : null}
            </>
          )}
        </SMVerticalDiv>
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingReservation
            setIsEditingReservation(!isEditingReservation)
            if (wantsToSaveChanges) {
              handleSaveDiscountChange({
                isReservable: isReservableInput,
                reservationLink: reservationLinkInput,
              })
            }
          }}
        >
          {isEditingReservation ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>
      <SMPrimaryButton
        variant="contained"
        loading={isLoadingAiFields}
        color="secondary"
        sx={{ margin: '8px 0px' }}
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          setIsLoadingAiFields(true)
          request(`re_do_discount_ai_fields/${discount.id}`).then((response) => {
            console.log(response?.data || 'no data')
            setIsLoadingAiFields(false)
          })
        }}
      >
        ↺ AI
      </SMPrimaryButton>
      <SMPrimaryButton
        variant="contained"
        loading={isLoadingGMapsFields}
        color="primary"
        sx={{ margin: '2px 2px' }}
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          setIsLoadingGMapsFields(true)
          request(`update_one_discount_gmaps/${discount.id}`).then((response) => {
            console.log(response?.data || 'no data')
            setIsLoadingGMapsFields(false)
          })
        }}
      >
        ↺ GM
      </SMPrimaryButton>
      <SMPrimaryButton
        sx={{ backgroundColor: 'green' }}
        href={
          'https://console.firebase.google.com/u/0/project/star-15bb5/firestore/databases/-default-/data/~2Fdiscounts~2F' +
          discount?.id
        }
      >
        BD
      </SMPrimaryButton>
      <SMPrimaryButton
        variant="contained"
        loading={isLoadingHide}
        sx={{ margin: '2px 2px', backgroundColor: 'black' }}
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          setIsLoadingHide(true)
          request(`hide/${discount.id}`).then((response) => {
            console.log(response?.data || 'no data')
            setIsLoadingHide(false)
          })
        }}
      >
        d=f
      </SMPrimaryButton>
    </div>
  )
}
