import React from 'react'

import '../../../App.css'

import { ProfileScreen } from './ProfileScreen'
import { SavedDiscounts } from './SavedDiscounts'
import { DiscountsDisplayEnum, DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'
import { LoadingDiscountsScreen } from '../components/LoadingDiscountsScreen'
import { DiscountDisplays } from './DiscountDisplays'
import { Discount } from '../../../types/discount'

export const DiscountViewsRouter = function ({
  isStar,
  isLoadingDiscounts,
  discounts,
  discountsSortedByDcto,
  discountsSortedByKms,
  view,
  displayMode,
}: {
  isStar: boolean
  isLoadingDiscounts: boolean
  discounts: Discount[]
  discountsSortedByDcto: Discount[]
  discountsSortedByKms: Discount[]
  view: DiscountsViewsEnum
  displayMode: DiscountsDisplayEnum
}) {
  if (
    isLoadingDiscounts &&
    (view === DiscountsViewsEnum.DISCOUNT_DISPLAY || view === DiscountsViewsEnum.SAVED_LIST)
  ) {
    return <LoadingDiscountsScreen />
  }
  if (view === DiscountsViewsEnum.DISCOUNT_DISPLAY) {
    return (
      <DiscountDisplays
        isStar={isStar}
        discounts={discounts}
        discountsSortedByDcto={discountsSortedByDcto}
        discountsSortedByKms={discountsSortedByKms}
        displayMode={displayMode}
      />
    )
  }
  if (view === DiscountsViewsEnum.SAVED_LIST) return <SavedDiscounts discounts={discounts} />
  if (view === DiscountsViewsEnum.ACCOUNT) return <ProfileScreen />
  return <ProfileScreen />
}
