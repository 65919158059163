import { createContext } from 'react'
import { DEFAULT_FILTERS, FilterOptionsType } from '../consts/filterOptions'
import { UserLocation } from '../types/main'
import { ClubEnums, ClubsTypeDB } from '../types/clubs'

export type OpenSnackBarProps = {
  message: string
}

export const defaultSnackBarProps: OpenSnackBarProps = {
  message: '',
}

export type UserAuthType = {
  id: string
  secretToken: string
  rut: string
  name?: string
  email?: string
  phoneNumber?: string
  userType?: string
  username?: string
  savedDiscounts?: string[]
}

export type ClubsType = {
  [key in ClubEnums]: ClubsTypeDB
}

interface AppContextType {
  user: string
  clubs: ClubsType | null
  filtersSelected: FilterOptionsType
  setFiltersSelected: (filters: FilterOptionsType) => void
  usersLocation: UserLocation | null
  setUsersLocation: (location: UserLocation) => void
  auth: UserAuthType | null
  setAuth: (auth: UserAuthType | null) => void
  openSnackBar: boolean
  handleOpenSnackBar: (props: OpenSnackBarProps) => void
  setNavButtonClicked: (name: string | false) => void
}

export const AppContext = createContext<AppContextType>({
  user: '',
  clubs: null,
  filtersSelected: DEFAULT_FILTERS,
  setFiltersSelected: () => {},
  usersLocation: null,
  setUsersLocation: () => {},
  auth: null,
  setAuth: () => {},
  openSnackBar: false,
  handleOpenSnackBar: () => {},
  setNavButtonClicked: () => {},
})
