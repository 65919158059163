import React, { useState, useEffect } from 'react'

import '../../../App.css'
import { DiscountViewsRouter } from './DiscountViewsRouter'
import { LoginDrawer } from '../components/LoginDrawer'
import {
  DiscountContext,
  DiscountContextType,
  OpenLoginDrawerProps,
  OpenReportErrorProps,
  OpenReservationProps,
  defaultLoginDrawerProps,
  defaultReportErrorDrawerProps,
  defaultReservationDrawerProps,
} from '../../../contexts/DiscountContext'
import { ReservationDrawer } from '../components/ReservationDrawer'
import { ReportErrorDrawer } from '../components/ReportErrorDrawer'
import { getUrlParam, setUrlParam } from '../../../helpers/others'
import { Discount } from '../../../types/discount'
import { DiscountDrawer } from '../components/DiscountDrawer'
import { DiscountsDisplayEnum, DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'
import { MenuDrawer } from '../components/MenuDrawer'

export const DiscountsScreenWithContext = function ({
  isStar,
  isLoadingDiscounts,
  discounts,
  discountsSortedByDcto,
  discountsSortedByKms,
  defaultView,
  displayMode,
}: {
  isStar: boolean
  isLoadingDiscounts: boolean
  discounts: Discount[]
  discountsSortedByDcto: any
  discountsSortedByKms: any
  defaultView: DiscountsViewsEnum
  displayMode: DiscountsDisplayEnum
}) {
  const urlOpenDiscountId = getUrlParam('openDiscountId')
  const [discountInDrawer, setDiscountInDrawer] = useState<Discount | null | undefined>(null)
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false)

  useEffect(() => {
    if (urlOpenDiscountId) {
      const preOpenedDiscountByUrl = discounts.find(
        (discount: Discount) => discount.id === urlOpenDiscountId
      )
      setDiscountInDrawer(preOpenedDiscountByUrl)
    }
  }, [urlOpenDiscountId, discounts])

  const handleDiscountClick = (discount: Discount | undefined) => {
    setUrlParam('openDiscountId', discount?.id || '')
    if (process.env.NODE_ENV !== 'production') {
      console.log(discount)
    }
    setDiscountInDrawer(discount)
  }

  // state for discountContext
  const [openLoginDrawer, setOpenLoginDrawer] = useState(false)
  const [loginDrawerProps, setLoginDrawerProps] = useState(defaultLoginDrawerProps)

  const [openReportErrorDrawer, setOpenReportErrorDrawer] = useState(false)
  const [reportErrorDrawerProps, setReportErrorDrawerProps] = useState(
    defaultReportErrorDrawerProps
  )

  const [openReservationDrawer, setOpenReservationDrawer] = useState(false)
  const [reservationDrawerProps, setReservationDrawerProps] = useState(
    defaultReservationDrawerProps
  )

  const [view, setView] = useState(defaultView)

  const discountContextValue: DiscountContextType = {
    openLoginDrawer,
    handleOpenLoginDrawer: (props: OpenLoginDrawerProps) => {
      setLoginDrawerProps(props)
      setOpenLoginDrawer(true)
    },
    openReportErrorDrawer,
    handleOpenReportErrorDrawer: (props: OpenReportErrorProps) => {
      setReportErrorDrawerProps(props)
      setOpenReportErrorDrawer(true)
    },
    openReservationDrawer,
    handleOpenReservationDrawer: (props: OpenReservationProps) => {
      setReservationDrawerProps(props)
      setOpenReservationDrawer(true)
    },
    handleDiscountClick,
    setOpenMenuDrawer,
  }

  let childrenDiscounts: Discount[] | null = null
  if (discountInDrawer?.childrenIds) {
    childrenDiscounts = discountInDrawer.childrenIds
      .map((cId) => discounts.find((d) => d.id === cId))
      .filter((d): d is Discount => d !== undefined)
  } else if (discountInDrawer?.parentId) {
    childrenDiscounts = discounts
      .filter((d) => d.id === discountInDrawer.parentId)
      .filter((d): d is Discount => d !== undefined)
  }

  return (
    <DiscountContext.Provider value={discountContextValue}>
      <DiscountViewsRouter
        isStar={isStar}
        isLoadingDiscounts={isLoadingDiscounts}
        discounts={discounts}
        discountsSortedByDcto={discountsSortedByDcto}
        discountsSortedByKms={discountsSortedByKms}
        view={view}
        displayMode={displayMode}
      />
      <MenuDrawer
        setView={setView}
        openMenuDrawer={openMenuDrawer}
        setOpenMenuDrawer={setOpenMenuDrawer}
      />
      <DiscountDrawer
        discount={discountInDrawer || null}
        isStar={isStar}
        childrenDiscounts={childrenDiscounts}
      />
      <LoginDrawer
        open={openLoginDrawer}
        setOpen={setOpenLoginDrawer}
        loginDrawerProps={loginDrawerProps}
      />
      <ReportErrorDrawer
        open={openReportErrorDrawer}
        setOpen={setOpenReportErrorDrawer}
        reportErrorDrawerProps={reportErrorDrawerProps}
      />
      <ReservationDrawer
        open={openReservationDrawer}
        setOpen={setOpenReservationDrawer}
        reservationDrawerProps={reservationDrawerProps}
      />
    </DiscountContext.Provider>
  )
}
