// Este comentario me imagino que es un error xq si esta en las dependencias
import { InView } from 'react-intersection-observer'
import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@mui/material'

import styles from './discountsHolder.module.css'
import DiscountCard from './DiscountCard'
import { Discount } from '../../../types/discount'

const { discountAndDescriptionFrame, discountsHolder, finishDot } = styles

const DISCOUNTS_TO_RENDER_EACH_LOADING = 12 // (3x4) so it can display nice in any screen

const DiscountsHolder = function ({
  discountsToShow,
  isStar,
}: {
  discountsToShow: Discount[]
  isStar: boolean
}) {
  const [amountDiscountsToRender, setAmountDiscountsToRender] = useState(
    DISCOUNTS_TO_RENDER_EACH_LOADING
  )
  const initialTrackerAndLoadingValue = discountsToShow?.length > DISCOUNTS_TO_RENDER_EACH_LOADING
  const [showTracker, setShowTracker] = useState(initialTrackerAndLoadingValue)
  // const [showLoading, setShowLoading] = useState(initialTrackerAndLoadingValue)

  const handleVisibility = (isVisible: boolean) => {
    if (isVisible && showTracker) {
      // revisar si showTracker = true no es necesario pero lo dejamos igual
      setShowTracker(false)
      setAmountDiscountsToRender(amountDiscountsToRender + DISCOUNTS_TO_RENDER_EACH_LOADING)
      setTimeout(() => {
        // esperamos un ratito antes de mostrar el nuevo tracker
        if (amountDiscountsToRender < discountsToShow?.length) {
          setShowTracker(true)
        }
      }, 1500)
      clearTimeout(0)
    }
  }

  useEffect(() => {
    // en caso de que cambien los filtros, es necesario resetear los estados
    setShowTracker(initialTrackerAndLoadingValue)
    // setShowLoading(initialTrackerAndLoadingValue)
    setAmountDiscountsToRender(DISCOUNTS_TO_RENDER_EACH_LOADING)
  }, [discountsToShow])

  return (
    <div className={discountAndDescriptionFrame}>
      <div className={discountsHolder}>
        {discountsToShow?.slice(0, amountDiscountsToRender).map((discount) => {
          return <DiscountCard discount={discount} isStar={isStar} key={discount.id} />
        })}
      </div>
      {showTracker && (
        <InView onChange={handleVisibility} threshold={0.5}>
          {/* <div style={{ color: 'white', fontSize: '5px' }}>Tracker</div> */}
          <CircularProgress style={{ marginBottom: '100px', marginTop: '20px' }} />
        </InView>
      )}
      {amountDiscountsToRender >= discountsToShow?.length ? <div className={finishDot} /> : <div />}
    </div>
  )
}

export default DiscountsHolder
