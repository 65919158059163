import './intro.css'
import '../../../App.css'
import { useNavigate } from 'react-router-dom'
import { Button, Switch } from '@mui/material'
import React, { useContext } from 'react'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { DayCheckBox } from '../components/DayCheckBox'
import { AppContext } from '../../../contexts/AppContext'
import { handleSwitchState } from '../../../helpers/handleFilterSelection'
import { logSMEvent } from '../../../events/logEvent'
import { SMH2 } from '../../../SMComponents/SMText'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { useViewportHeight } from '../../../hooks/useViewportHeight'
import { PrimaryButton } from '../../common/SMButton'
import { isAppInstalled, isiOS } from '../../../helpers/device'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { primaryPrimary } from '../../../style'

export const SelectDaysSecondLanding = function () {
  const navigate = useNavigate()
  const filterLabel = 'Dias'
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()
  const handleClickBack = () => {
    window.scrollTo(0, 0)
    logSMEvent('CLICK_BACK_IN_SECOND_LANDING_DAYS')
    return navigate('/welcome_back')
  }

  const handleClickNext = () => {
    window.scrollTo(0, 0)
    logSMEvent('CLICK_BACK_IN_SECOND_LANDING_DAYS', {
      days: filtersSelected.Dias.join(','),
    })
    return navigate('/descuentos')
  }

  const viewportHeight = useViewportHeight()

  return (
    <div className="full-screen">
      <div className="holder">
        <div className="main-page" style={{ minHeight: viewportHeight - 32 }}>
          <SMHorizontalDiv maxSpaceBetween sx={{ marginBottom: 18 }}>
            <Button onClick={handleClickBack} style={{ minWidth: 30 }}>
              <ArrowBackIosNewRoundedIcon style={{ color: primaryPrimary }} />
            </Button>
            <div style={{ minWidth: 30 }} />
          </SMHorizontalDiv>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <SMH2>¿Para qué días de la semana?</SMH2>
          </div>
          <div className={'body-days'}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '10px',
                maxWidth: '325px',
                width: '100%',
              }}
            >
              <p>Seleccionar todos</p>
              <Switch
                checked={
                  filtersSelected[filterLabel]?.length === filterOptions[filterLabel]?.length
                }
                onClick={() => {
                  handleSwitchState(filterLabel, filtersSelected, setFiltersSelected, filterOptions)
                }}
              />
            </div>
            {filterOptions.Dias.map((day) => {
              return <DayCheckBox key={day} day={day} />
            })}
          </div>
          <div className="footer" style={isiOS && !isAppInstalled ? { marginBottom: '90px' } : {}}>
            <PrimaryButton onClick={handleClickNext} buttonname={`NEXT_INTRO_SECOND_LANDING_PAGE`}>
              <p className="button-1">Siguiente</p>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
