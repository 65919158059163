import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import './App.css'
import { request } from './helpers/request'
import {
  AppContext,
  ClubsType,
  defaultSnackBarProps,
  OpenSnackBarProps,
  UserAuthType,
} from './contexts/AppContext'
import { DEFAULT_FILTERS, FilterOptionsEnum, sortOptions } from './consts/filterOptions'
import { ALLMAINTAGSV3, MAINTAGV3Enum } from './types/mainTagV3'
import {
  addDiscountsXClosestLocation,
  addDiscountsXGoogleMapsLink,
} from './helpers/discountLocationsProperties'
import { sortDiscount } from './helpers/filterDiscounts'
import { logSMEvent, stringifyFiltersSelected } from './events/logEvent'

import { SelectClubs } from './views/intro/screens/SelectClubs'
import { DiscountsScreenWithContext } from './views/discounts/screens/DiscountsScreenWithContext'
import { NoConnection } from './views/discounts/screens/NoConnection'
import { SelectMainTagV3 } from './views/intro/screens/SelectCategories'
import { SecondLanding } from './views/landing/screens/SecondLanding'
import { Landing } from './views/landing/screens/Landing'
import { SelectDays } from './views/intro/screens/SelectDays'

import { getDaysFilters } from './helpers/getDaysFilters'
import { getUsersLocation } from './hooks/useCurrentLocation'
import mockScraperRequest from './fake-data/mockScraperRequest.json' // del scraper
import mockDiscountsRequest from './fake-data/mockDiscountsRequest.json'
import { decompress } from './helpers/decompress'
import { isAppInstalled } from './helpers/device'
import { appVersion } from './consts/version'
import { getUrlParam } from './helpers/others'
import { DiscountsErrors } from './views/admin/DiscountsErrors'
import { SMSnackBar } from './SMComponents/SMSnackBar'
import { DiscountsDisplayEnum, DiscountsViewsEnum } from './enums/DiscountsViewsEnum'
import { IS_DEV_ENV } from './consts/env'
import { ClubsTypeDB } from './types/clubs'
import { UserLocation } from './types/main'
import { Discount } from './types/discount'
import { SelectLocation } from './views/intro/screens/SelectLocation'
import FilterDrawer from './views/discounts/components/swipeableDrawer/FilterDrawer'
import { SelectDaysSecondLanding } from './views/intro/screens/SelectDaysSecondLanding'

const expires = new Date(1830000000000) // hasta el 2027
const path = '/'

const importFakeData: string | boolean = IS_DEV_ENV && 'FALSE' && 'bbdd' // 'backend', 'scraper', 'bbdd'

export const App = function () {
  const [cookies, setCookie] = useCookies<any>(['savemoney'])
  let { user, amountofvisits16 } = cookies
  const [auth, setAuth] = useState<UserAuthType | null>(null)
  // pasamos la cookie de visitas a un número
  amountofvisits16 = amountofvisits16 ? `${parseInt(amountofvisits16, 10) + 1}` : '1'
  const [discounts, setDiscounts] = useState([])
  const [clubs, setClubs] = useState<ClubsType | null>(null)
  // la lista de descuentos que viene del los clubs, ordenada por relevancia

  // la lista de descuentos ordenada por kms
  const [discountsSortedByKms, setDiscountsSortedByKms] = useState<Discount[]>([])
  // la lista de descuentos ordenada por dcto
  const [discountsSortedByDcto, setDiscountsSortedByDcto] = useState<Discount[]>([])
  // variable que indica cuando se han cargado todos los descuentos (clubs + company + provider)
  const [isLoadingDiscounts, setIsLoadingDiscounts] = useState(true)
  // ubicacion del usuario
  const [usersLocation, setUsersLocation] = useState<UserLocation | null>(null)
  // filtros seleccionados
  const [filtersSelected, setFiltersSelected] = useState(DEFAULT_FILTERS)

  // try to log in
  useEffect(() => {
    const login = async (userId: number, secretToken: string) => {
      const response = await request('login_user', {
        method: 'POST',
        body: JSON.stringify({
          userId,
          secretToken,
        }),
      })
      const user = response.data
      setAuth(user)
    }
    const userId = cookies?.userId
    const secretToken = cookies?.secretToken
    if (secretToken && userId) {
      login(userId, secretToken)
    }
  }, [])

  // al inicio, cambiar los filtros por lo que hay en la URL o en las cookies + algunas configs
  useEffect(() => {
    // set filters with cookies
    const initialFiltersSelected = DEFAULT_FILTERS
    initialFiltersSelected.Tarjetas =
      cookies?.filtersSelected?.Tarjetas || DEFAULT_FILTERS[FilterOptionsEnum.TARJETAS]
    initialFiltersSelected.SubTarjeta =
      cookies?.filtersSelected?.SubTarjeta || DEFAULT_FILTERS[FilterOptionsEnum.SUBTARJETA]
    initialFiltersSelected.Ubicacion =
      cookies?.filtersSelected?.Ubicacion || DEFAULT_FILTERS[FilterOptionsEnum.UBICACION]
    initialFiltersSelected.MainTagV3 =
      cookies?.filtersSelected?.MainTagV3 || DEFAULT_FILTERS[FilterOptionsEnum.MAINTAGV3]
    initialFiltersSelected.Sort =
      cookies?.filtersSelected?.Sort || DEFAULT_FILTERS[FilterOptionsEnum.SORT]

    initialFiltersSelected.BenefitType = DEFAULT_FILTERS[FilterOptionsEnum.BENEFIT_TYPE]
    initialFiltersSelected.Dias = getDaysFilters(cookies, setCookie, amountofvisits16)
    initialFiltersSelected.MinDiscountsAmount =
      DEFAULT_FILTERS[FilterOptionsEnum.MIN_DISCOUNT_AMOUNT]

    // sacamos de las filterSelected aquellas que ya no existen
    const selectedTags = [...initialFiltersSelected.MainTagV3]
    const newInitialMainTagsV3: MAINTAGV3Enum[] = []
    selectedTags.forEach((t) => {
      if (ALLMAINTAGSV3.includes(t)) newInitialMainTagsV3.push(t)
    })
    initialFiltersSelected.MainTagV3 = [...newInitialMainTagsV3]
    initialFiltersSelected.Mapa = DEFAULT_FILTERS[FilterOptionsEnum.MAP]
    logSMEvent('USER_INTIAL_FILTERS_SELECTED', stringifyFiltersSelected(initialFiltersSelected))
    setFiltersSelected(initialFiltersSelected)
  }, [])

  // log events y setear cookies user y amountOf visits
  useEffect(() => {
    // log events
    user = user || Math.random().toString(36).substring(2)
    setCookie('user', user, {
      path,
      expires,
    })
    setCookie('amountofvisits16', amountofvisits16, {
      path,
      expires,
    })
    const sharedId = getUrlParam('shareId')
    logSMEvent('USER_VISIT', {
      user,
      app_version: appVersion,
      visit_counter: parseInt(amountofvisits16, 10),
      first_time: amountofvisits16 === '1',
      is_app_installed: isAppInstalled,
      sharedId,
    })
  }, [])

  // cambiar los filtros en las cookies cada vez que cambian los filtros
  useEffect(() => {
    setCookie('filtersSelected', filtersSelected, {
      path,
      expires,
    })
    setCookie('lastTimeAppUsed', new Date().getTime(), {
      path,
      expires,
    })
  }, [filtersSelected])

  // ir a buscar los descuentos
  useEffect(() => {
    // call async function without await inside a useEffect
    const fetchDiscounts = async () => {
      try {
        const initialTime = new Date().getTime()
        const response = await request('token_V2')
        const validDiscounts = JSON.parse(
          decompress(`${response?.data?.token}${response?.data?.cookies}`)
        ).discounts
        const deltaTime = ((new Date().getTime() - initialTime) / 1000).toFixed(1)
        logSMEvent('DISCOUNTS_LOAD_TIME', { deltaTime })
        setDiscounts(validDiscounts)
        setIsLoadingDiscounts(false)
      } catch {
        setIsLoadingDiscounts(false)
      }
    }
    if (importFakeData === 'bbdd') {
      request('db_discount').then((response) => {
        setDiscounts(response?.data?.discounts || [])
        setIsLoadingDiscounts(false)
      })
    } else if (importFakeData === 'backend') {
      setDiscounts((mockDiscountsRequest as any).data.discounts)
      setIsLoadingDiscounts(false)
    } else if (importFakeData === 'scraper') {
      const discountsMockedFromScraper = mockScraperRequest.discounts.map((d) => {
        return d
      })
      setDiscounts(discountsMockedFromScraper)
      setIsLoadingDiscounts(false)
    } else {
      fetchDiscounts()
    }
  }, [])

  // ir a buscar los clubs
  useEffect(() => {
    // call async function without await inside a useEffect
    const fetchClubs = async () => {
      try {
        const response = await request('clubs')
        const clubsAsJsonWidhIdAsKey: ClubsType = response?.data.reduce(
          (
            acc: {
              [key: string]: ClubsTypeDB
            },
            club: ClubsTypeDB
          ) => {
            if (club.display !== false && club.id) {
              acc[club.id] = club
            }
            return acc
          },
          {}
        )
        setClubs(clubsAsJsonWidhIdAsKey)
      } catch {
        setClubs(null)
      }
    }
    fetchClubs()
  }, [])

  // get users location every time user sort for kms
  useEffect(() => {
    if (filtersSelected?.Sort?.length === 1 && filtersSelected?.Sort?.includes(sortOptions.KMS)) {
      getUsersLocation(setUsersLocation)
    }
  }, [filtersSelected?.Sort])

  // agregar XClosestLocation y XGoogleMapsLink cada vez que cambia la ubicación
  useEffect(() => {
    if (!isLoadingDiscounts && usersLocation && !usersLocation.error) {
      const discountsWithXArgs = discounts.map((discount) => {
        addDiscountsXClosestLocation(discount, usersLocation)
        addDiscountsXGoogleMapsLink(discount)
        return discount
      })
      setDiscounts(discountsWithXArgs)
    }
    // no puede depender de discounts porque se modifica en este useEffect
  }, [isLoadingDiscounts, usersLocation])

  // generar las listas de descuentos ordenadas por kms y por dcto
  useEffect(() => {
    if (!isLoadingDiscounts && discounts) {
      setDiscountsSortedByKms(sortDiscount(discounts, 'KMS'))
      // esto se podria optimizar para que no se vuelva a ordenar por
      // dcto cuando cambia el usersLocation
      setDiscountsSortedByDcto(sortDiscount(discounts, 'DCTO'))
    }
  }, [isLoadingDiscounts, discounts])

  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [navButtonClicked, setNavButtonClicked] = useState<string | false>(false)
  const [snackBarProps, setSnackBarProps] = useState(defaultSnackBarProps)

  const appContextValue = {
    user,
    clubs,
    filtersSelected,
    setFiltersSelected,
    usersLocation,
    setUsersLocation,
    auth,
    setAuth,
    openSnackBar,
    handleOpenSnackBar: (
      props: OpenSnackBarProps
      //: OpenSnackBarProps <- el type
    ) => {
      setSnackBarProps(props)
      setOpenSnackBar(true)
    },
    setNavButtonClicked,
  }

  if (!isLoadingDiscounts && (!discounts || discounts.length === 0) && !importFakeData)
    return <NoConnection />
  return (
    <AppContext.Provider value={appContextValue}>
      <BrowserRouter>
        <SMSnackBar open={openSnackBar} setOpen={setOpenSnackBar} snackBarProps={snackBarProps} />
        <FilterDrawer navButtonClicked={navButtonClicked} />

        <Routes>
          <Route path="/" element={amountofvisits16 === '1' ? <Landing /> : <Landing />} />
          <Route
            path="descuentos"
            element={
              <DiscountsScreenWithContext
                isStar={false}
                isLoadingDiscounts={isLoadingDiscounts}
                discounts={discounts}
                discountsSortedByDcto={discountsSortedByDcto}
                discountsSortedByKms={discountsSortedByKms}
                defaultView={DiscountsViewsEnum.DISCOUNT_DISPLAY}
                displayMode={DiscountsDisplayEnum.LIST}
              />
            }
          />
          <Route
            path="descuentos/lista"
            element={
              <DiscountsScreenWithContext
                isStar={false}
                isLoadingDiscounts={isLoadingDiscounts}
                discounts={discounts}
                discountsSortedByDcto={discountsSortedByDcto}
                discountsSortedByKms={discountsSortedByKms}
                defaultView={DiscountsViewsEnum.DISCOUNT_DISPLAY}
                displayMode={DiscountsDisplayEnum.LIST}
              />
            }
          />
          <Route
            path="descuentos/tabla"
            element={
              <DiscountsScreenWithContext
                isStar={false}
                isLoadingDiscounts={isLoadingDiscounts}
                discounts={discounts}
                discountsSortedByDcto={discountsSortedByDcto}
                discountsSortedByKms={discountsSortedByKms}
                defaultView={DiscountsViewsEnum.DISCOUNT_DISPLAY}
                displayMode={DiscountsDisplayEnum.TABLE}
              />
            }
          />
          <Route
            path="descuentos/mapa"
            element={
              <DiscountsScreenWithContext
                isStar={false}
                isLoadingDiscounts={isLoadingDiscounts}
                discounts={discounts}
                discountsSortedByDcto={discountsSortedByDcto}
                discountsSortedByKms={discountsSortedByKms}
                defaultView={DiscountsViewsEnum.DISCOUNT_DISPLAY}
                displayMode={DiscountsDisplayEnum.MAP}
              />
            }
          />
          <Route
            path="star"
            element={
              <DiscountsScreenWithContext
                isStar={true}
                isLoadingDiscounts={isLoadingDiscounts}
                discounts={discounts}
                discountsSortedByDcto={discountsSortedByDcto}
                discountsSortedByKms={discountsSortedByKms}
                defaultView={DiscountsViewsEnum.DISCOUNT_DISPLAY}
                displayMode={DiscountsDisplayEnum.TABLE}
              />
            }
          />
          <Route path="clubs" element={<SelectClubs />} />
          <Route path="categories" element={<SelectMainTagV3 />} />
          <Route path="days" element={<SelectDays />} />
          <Route path="onboard/days" element={<SelectDaysSecondLanding />} />
          <Route path="location" element={<SelectLocation />} />
          <Route path="landing" element={<Landing />} />
          <Route path="welcome_back" element={<SecondLanding />} />

          {/* admin */}
          <Route path="admin/discounts_errors" element={<DiscountsErrors />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  )
}
