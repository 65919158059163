export const getUrlParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param) || ''
}

export const setUrlParam = (param: string, value: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set(param, value || '')
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
}

export const DAYS_ORDER = ['L', 'M', 'W', 'J', 'V', 'S', 'D']

export const DAYS_ABREVIATIONS: {
  [key: string]: string
} = {
  'NOT FOUND': 'Todos',
  '': 'Todos',
  'L,M': 'Lun. y Mar.',
  'L,M,W': 'Lun. a Mié.',
  'L,M,W,J': 'Lun. a Jue.',
  'L,M,W,J,V': 'Lun. a Vie.',
  'L,M,W,J,V,S': 'Lun. a Sáb.',
  'L,M,W,J,V,S,D': 'Todos',
  'M,W': 'Mar. y Mié.',
  'M,W,J': 'Mar. a Jue.',
  'M,W,J,V': 'Mar. a Vie.',
  'M,W,J,V,S': 'Mar. a Sáb.',
  'M,W,J,V,S,D': 'Mar. a Dom.',
  'W,J': 'Mié. y Jue.',
  'W,J,V': 'Mié. a Vie.',
  'W,J,V,S': 'Mié. a Sáb.',
  'W,J,V,S,D': 'Mié. a Dom.',
  'J,V': 'Jue. y Vie.',
  'J,V,S': 'Jue. a Sáb.',
  'J,V,S,D': 'Jue. a Dom.',
  'V,S': 'Vie. y Sáb.',
  'V,S,D': 'Vie. a Dom.',
  'S,D': 'Sáb. y Dom.',
  'L,M,W,J,D': 'Lun. a Jue. y Dom.',
  'L,M,W,J,V,D': 'Lun. a Vie. y Dom.',
  'L,W': 'Lun. y Mié.',
  L: 'Lun.',
  M: 'Mar.',
  W: 'Mie.',
  J: 'Jue.',
  V: 'Vie.',
  S: 'Sáb.',
  D: 'Dom.',
}

export const NAME_OF_DAYS: { [key: string]: string } = {
  L: 'Lunes',
  M: 'Martes',
  W: 'Miércoles',
  J: 'Jueves',
  V: 'Viernes',
  S: 'Sábado',
  D: 'Domingo',
}

export const normalizeDaysToText = (normalizeDays: string): string => {
  // normalmente entra discount.diasNormalizados, pero puede entrar filtersSelected.Dias.join(', ') tmbn
  if (!normalizeDays) return 'Todos'
  const daysLabelSorted = normalizeDays
    .split(',')
    .sort((a, b) => DAYS_ORDER.indexOf(a) - DAYS_ORDER.indexOf(b))
    .join(',')
  const newAbreviation = DAYS_ABREVIATIONS[daysLabelSorted] || daysLabelSorted
  const changeEachDay = newAbreviation
    .split(',')
    .map((day) => DAYS_ABREVIATIONS[day] || day)
    .join(', ')
  return changeEachDay
}

export const addUtmParamsToUrl = (item: { url: string; id?: string }) => {
  try {
    let basedUrl = item.url
    // Check if URL starts with http or https
    if (!/^https?:\/\//i.test(basedUrl)) {
      // If not, prepend 'http://'
      basedUrl = 'http://' + basedUrl
    }
    const utmParams: {
      [key: string]: string
    } = {
      utm_source: 'savemoney',
      utm_medium: 'referral',
      // utm_campaign: item.id,
      utm_term: item.id || '',
    }
    const url = new URL(basedUrl)
    Object.keys(utmParams).forEach((key) => url.searchParams.append(key, utmParams[key]))
    return url.toString()
  } catch (error) {
    return ''
  }
}
