import React from 'react'
import { Container, Typography, Button, Box, IconButton, Stack } from '@mui/material'
import Grid from '@mui/material/Grid2'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TikTokIcon from '@mui/icons-material/MusicNote' // Substitute icon for TikTok
import { appVersion } from '../../../consts/version'

export const SecondLanding = () => {
  return (
    <Container sx={{ minWidth: '100%' }}>
      {/* Full page with horizontal margin Section */}
      <Container maxWidth="xl">
        {/* Header Section */}
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* Logo  */}
            <img
              src="https://via.placeholder.com/50" // Replace with your logo URL
              alt="Logo"
              style={{ width: '50px', height: '50px', borderRadius: '50%' }}
            />
            <Typography variant="h5" component="h2">
              SaveMoney
            </Typography>
          </Stack>
          {/* by wayoalamos ©  */}
          <Stack direction="row" spacing={10}>
            {/* <Typography>Trabaja con Nosotros</Typography> */}
            <Typography variant="body2" color="textSecondary">
              by wayoalamos ©
            </Typography>
          </Stack>
        </Stack>
        {/* Hero Section */}
        <Stack spacing={4}>
          {/* Hero: Title with phone frames grid*/}
          <Grid container spacing={10}>
            {/* Title description try-it button */}
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography variant="h2" component="h1" gutterBottom>
                Aprovecha tus descuentos
              </Typography>
              <Typography variant="h6" color="textSecondary" paragraph>
                La aplicación que reúne los descuentos de: tus tarjetas bancarias, tus tarjetas
                vecinos, tu compañía de celular, tus billeteras digitales y más.
              </Typography>
              <Button variant="contained" color="primary" size="large" sx={{ mt: 2 }}>
                Prueba la versión web
              </Button>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                Al continuar aceptas los términos y condiciones
              </Typography>
            </Grid>
            {/* Phone frame with store buttons */}
            <Grid
              container
              direction="column"
              alignItems="center"
              size={{ xs: 12, sm: 6 }}
              spacing={4}
            >
              {/* Phone Frame with App Screenshot */}
              <Grid>
                <Box
                  sx={{
                    border: '2px solid #ccc',
                    borderRadius: '16px',
                    width: '250px',
                    height: '500px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <img
                    src="https://via.placeholder.com/200x400" // Replace with the app screenshot URL
                    alt="App Screenshot"
                    style={{ width: '90%', height: '90%', borderRadius: '10px' }}
                  />
                </Box>
              </Grid>
              {/* Store Buttons */}
              <Grid>
                <Stack direction="row" spacing={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ mr: 2 }}
                    href="https://play.google.com" // Replace with your app's Google Play Store link
                  >
                    Google Play Store
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    href="https://www.apple.com/app-store/" // Replace with your app's App Store link
                  >
                    App Store
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          {/* Hero: Social Media Section */}
          <Box textAlign="center">
            <Typography variant="h6" component="p" gutterBottom>
              Síguenos en redes sociales
            </Typography>
            <Box>
              <IconButton
                aria-label="Facebook"
                href="https://facebook.com" // Replace with your Facebook link
                target="_blank"
              >
                <FacebookIcon fontSize="large" />
              </IconButton>
              <IconButton
                aria-label="Instagram"
                href="https://instagram.com" // Replace with your Instagram link
                target="_blank"
              >
                <InstagramIcon fontSize="large" />
              </IconButton>
              <IconButton
                aria-label="TikTok"
                href="https://tiktok.com" // Replace with your TikTok link
                target="_blank"
              >
                <TikTokIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </Stack>

        {/* Footer */}
        <Box py={2} textAlign="center">
          <Typography variant="body2" color="textSecondary">
            Version - {appVersion}
          </Typography>
        </Box>
      </Container>
    </Container>
  )
}
