import React from 'react'
import { neutralBlack, neutralWhite, secondarySecondary } from '../style'

export const SMH1 = ({ white, center, style, sx, children, b, smref, ...rest }: any) => {
  return (
    <h1
      {...rest}
      ref={smref}
      style={{
        color: white ? neutralWhite : neutralBlack,
        margin: 0,
        textAlign: center && 'center',
        border: b && '1px solid red',
        ...style,
        ...sx,
      }}
    >
      {children}
    </h1>
  )
}

export const SMH2 = ({ white, center, style, sx, children, b, smref, ...rest }: any) => {
  return (
    <h2
      {...rest}
      ref={smref}
      style={{
        color: white ? neutralWhite : neutralBlack,
        margin: 0,
        textAlign: center && 'center',
        border: b && '1px solid red',
        ...style,
        ...sx,
      }}
    >
      {children}
    </h2>
  )
}

export const SMH3 = ({ white, center, style, sx, children, b, smref, ...rest }: any) => {
  return (
    <h3
      {...rest}
      ref={smref}
      style={{
        color: white ? neutralWhite : neutralBlack,
        margin: 0,
        textAlign: center && 'center',
        border: b && '1px solid red',
        ...style,
        ...sx,
      }}
    >
      {children}
    </h3>
  )
}

export const SMP = ({
  center,
  style,
  sx,
  children,
  white,
  black,
  small,
  bold,
  b,
  underline,
  fullWidth,
  ...rest
}: any) => {
  return (
    <p
      {...rest}
      style={{
        color: white ? neutralWhite : black ? neutralBlack : secondarySecondary,
        margin: 0,
        fontSize: small && '10px',
        textAlign: center && 'center',
        fontWeight: bold ? 'bold' : 'normal',
        border: b && '1px solid red',
        textDecoration: underline && 'underline',
        cursor: underline && 'pointer',
        width: fullWidth && '100%',
        ...style,
        ...sx,
      }}
    >
      {children}
    </p>
  )
}
